import React from "react";
import eMAG from "./../../../assets/emag.svg";
import Flanco from "./../../../assets/flanco.svg";
import Orange from "./../../../assets/orange.svg";
import evoMAG from "./../../../assets/evomag.svg";
import PCGarage from "./../../../assets/pcgarage.jpg";
import ForIT from "./../../../assets/forit.png";
import Vexio from "./../../../assets/vexio.png";
import ITGalaxy from "./../../../assets/itgalaxy.png";

const Product = ({
    documentID,
    name,
    thumbnail,
    price,
    stock,
    store,
    url,
    affiliate,
    description
}) => {
    if (!documentID || !name || !thumbnail || !price || !store || !url ||
        !affiliate || !description || typeof (stock) != "boolean") return null;

    let siteURL = url;
    // if ((store === "eMAG") || (store === "Orange")) siteURL = url;
    const paragraphs = description.split("\n\n");
    const descriptionParagraphs = paragraphs.map((paragraph, index) => (
        <p key={index}>{paragraph}</p>
    ));

    const windowLocation = window.location.href;
    let prefix = "";
    let resigilat = windowLocation.includes("resigilate");
    if (resigilat) {
        prefix = "RESIGILAT: ";
    }

    if (store === "Orange") siteURL = url;
    return (
        <div className="product">
            <div className="store">
                {(store === "eMAG") ? <img className={`${!stock && "not-in-stock"}`} src={eMAG} alt=""></img> : ""}
                {(store === "Flanco") ? <img className={`${!stock && "not-in-stock"}`} src={Flanco} alt=""></img> : ""}
                {(store === "Orange") ? <img className={`${!stock && "not-in-stock"}`} src={Orange} alt=""></img> : ""}
                {(store === "evoMAG") ? <img className={`${!stock && "not-in-stock"}`} src={evoMAG} alt=""></img> : ""}
                {(store === "PCGarage") ? <img className={`${!stock && "not-in-stock"}`} src={PCGarage} alt=""></img> : ""}
                {(store === "ForIT") ? <img className={`${!stock && "not-in-stock"}`} src={ForIT} alt=""></img> : ""}
                {(store === "Vexio") ? <img className={`${!stock && "not-in-stock"}`} src={Vexio} alt=""></img> : ""}
                {(store === "ITGalaxy") ? <img className={`${!stock && "not-in-stock"}`} src={ITGalaxy} alt=""></img> : ""}
                {!stock ? <span className="out-of-stock">Nu e în stoc</span> : <span className="in-stock">În stoc</span>}
            </div>
            <a href={siteURL}>
                <div className="thumb">
                    <img className={`${!stock && "not-in-stock"}`} src={`https://firebasestorage.googleapis.com/v0/b/alerte-stoc.appspot.com/o/${thumbnail}?alt=media`} alt=""></img>
                </div>
            </a>
            <div className="details">
                <ul>
                    <li>
                        <a href={siteURL}>
                            <span className="name">
                                <span>{prefix}</span>
                                {name}
                            </span>
                        </a>
                    </li>
                </ul>
                {<div className="price">
                    {`${new Intl.NumberFormat("ro-RO", { minimumFractionDigits: 2 }).format(price)}${store === "Orange" && url.includes("/telefoane/") ? "€" : " Lei"}`}
                </div>}

                {<div className="description">{descriptionParagraphs}</div>}
            </div>
        </div>
    );
}

export default Product;
