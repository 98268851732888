import React from "react";
import Button from "../forms/Button";

const LoadMore = ({
    onLoadMoreEvt = () => { },
}) => {
    return (
        <Button onClick={() => onLoadMoreEvt()}>
            Mai multe
        </Button>
    );
}

export default LoadMore;
