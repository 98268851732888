import React from "react";
import Console from "./../../assets/console.webp";
import "./styles.scss";

const Directory = () => {
    return (
        <div className="directory">
            <div className="wrap">
                <div className="item" style={{
                    backgroundImage: `url(${Console})`
                }}>
                    <ul>
                        <a href="/categories/console"> Console </a>
                        <a href="/categories/placi-video"> Plăci video </a>
                        <a style={{ background: "#009900" }} className="resealed" href="/categories/resigilate-top-favorite"> Resigilate </a>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Directory;
